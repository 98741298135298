import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import {
  getMemberInfo, getUserNewsList, getPackageType, country_list, homepage
} from "@/system/request/api/api";

Vue.use(VueGoodTablePlugin);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      isLoading: false,
      userInfo: {
        invest: 0,
        rank: {
          rank_name_en: ''
        }
      },
      currentAddress: '',
      username: '',
      teamList: [],
      newsList: [],
      countryOptions: [],
      country_code: '',
      lastNew: '',
      packageList: [],
      specialPackageList: [],
      newsItem: {
        description: ''
      },
      assetInfo: [
        { name: 'group_sales', value: '0', type: 'group_sales_bonus' },
        { name: 'matching', value: '0', type: 'matching_bonus' },
        { name: 'passive', value: '0', type: 'static_bonus' },
        { name: 'refferal', value: '0', type: 'sponsor_bonus' },
        { name: 'unilevel', value: '0', type: 'dynamic_bonus' },
        { name: 'withdrawal', value: '0', type: 'special_bonus' },
      ],
      // assetInfo: {
      //   group_sales: 0,
      //   matching: 0,
      //   passive: 0,
      //   refferal: 0,
      //   unilevel: 0,
      //   withdrawal: 0,
      //   static_bonus: 0,
      //   sponsor_bonus: 0,
      //   dynamic_bonus: 0,
      //   withdraw_bonus: 0,
      //   group_sales_bonus: 0,
      //   matching_bonus: 0,
      // },
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      },
      selectedWallet: 'point1'
    }
  },
  watch: {
    '$i18n.locale': 'getNewsList'
  },
  methods: {
    changeLan(lan) {
      if (lan == 'zh' || lan == 'en' || lan == 'in' || lan == 'zhHant' || lan == 'ja' || lan == 'ko') {
        localStorage.setItem('lan', lan);
        this.$i18n.locale = lan;

      } else {
        this.$root.makeToast("warning", this.$t('coming_soon'));
      }
    },
    getPackageType() {
      var self = this;
      self.isLoading = true;
      var result = getPackageType();
      result
        .then(function (value) {
          var packageList = value.data.data.user_group;
          self.packageList = [];
          self.specialPackageList = [];
          packageList.forEach(element => {
            if (element.id <= 7)
              self.packageList.push(element);

            else
              self.specialPackageList.push(element);
          });
          // packageList.filter(item => item.id > 7);
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getInfo() {
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      result
        .then(function (value) {
          self.isLoading = false;
          self.userInfo = value.data.data;
          if (value.data.data.password2 == null) {
            self.$router.push('/web/sessions/set-sec-password');
          }


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAssetInfo() {
      var self = this;
      self.isLoading = true;
      var result = homepage();
      result
        .then(function (value) {
          self.isLoading = false;
          var info = value.data.data;
          for (const key in info) {
            const element = info[key];

            self.assetInfo.forEach((item) => {
              if (key == item.name)
                item.value = element;

            });
          }
          // self.assetInfo = value.data.data;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCountryList() {
      var self = this;
      self.countryOptions = [];
      this.isLoading = true;
      var result = country_list();
      result
        .then(function (value) {
          self.$root.isLoading = false;
          var rootDataList = value.data.data;
          self.defaultCountry = value.data.data.find(el => el.id == 2);

          for (let i = 0; i < rootDataList.length; i++) {
            rootDataList[i]["symbol"] = rootDataList[i].short_form;
            rootDataList[i]["value"] = rootDataList[i].id;
            rootDataList[i]["code"] = rootDataList[i].country_code;
            rootDataList[i]["rate"] = rootDataList[i].sell;
            rootDataList[i]["name"] = self.$i18n.locale == 'en' ? rootDataList[i].name_en : rootDataList[i].name;

            self.countryOptions.push(rootDataList[i]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getNewsList() {
      var result = getUserNewsList(this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale, 1, 1);
      var self = this;
      result
        .then(function (value) {
          self.newsList = value.data.data.record.data;
          self.lastNew = value.data.data.latest;
          var popup = localStorage.getItem('popup') ?? '0';

          if (popup == '1') {
            setTimeout(() => {
              if (self.lastNew != null)
                for (let i = 0; i < self.lastNew.length; i++) {
                  console.log(self.lastNew[i]);
                  self.$bvModal.show('modal-newlatest-' + self.lastNew[i].id);
                }

            }, 1000);
            localStorage.setItem('popup', '0');

          }


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvToast.toast(msg, {
        // // title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };
      console.log(this.modal);

    }
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
