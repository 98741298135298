import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
// import checkWithdraw from "./system/auth/canWithdraw.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/web/",
    component: () => import("./views"),
    redirect: "/web/homepage",
    children: [
      {
        path: "homepage",
        beforeEnter: checkAuth,
        component: () => import("./views/home/homePage.vue"),
      },
      {
        path: "package",
        beforeEnter: checkAuth,
        component: () => import("./views/package/package.vue"),
      },
      {
        path: "wallet",
        beforeEnter: checkAuth,
        component: () => import("./views/wallet/wallet.vue"),
      },
      {
        path: "team",
        beforeEnter: checkAuth,
        component: () => import("./views/team/team.vue"),
      },
      {
        path: "swap",
        beforeEnter: checkAuth,
        component: () => import("./views/wallet/swap.vue"),
      },
      {
        path: "transfer",
        beforeEnter: checkAuth,
        component: () => import("./views/wallet/transfer.vue"),
      },
    ],
  },

  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/signIn"),
      },
      {
        path: "set-sec-password",
        component: () => import("./views/sessions/set-sec-password.vue"),
      },
      // {
      //   path: "aboutUs",
      //   component: () => import("./views/sessions/aboutUs"),
      // },
      // {
      //   path: "tnc",
      //   component: () => import("./views/sessions/tnc"),
      // },
    ],
  },
  {
    path: "/web/recentTransaction",
    beforeEnter: checkAuth,
    component: () => import("./views/home/recent_transaction.vue"),
  },
  {
    path: "/register",
    component: () => import("./views/sessions/signUp.vue"),
  },
  {
    path: "/web/profile",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/profile.vue"),
  },
  {
    path: "/web/setWithdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/setWithdraw.vue"),
  },
  {
    path: "/web/referral",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/referral.vue"),
  },
  {
    path: "/web/deposit",
    beforeEnter: checkAuth,
    component: () => import("./views/wallet/action/deposit.vue"),
  },
  {
    path: "/web/settings",
    beforeEnter: checkAuth,
    component: () => import("./views/profile/settings.vue"),
  },
  {
    path: "/web/withdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/wallet/action/withdraw.vue"),
  },
  {
    path: "/web/packageHistory",
    beforeEnter: checkAuth,
    component: () => import("./views/package/package_history.vue"),
  },
  {
    path: "/web/packageDetail",
    beforeEnter: checkAuth,
    component: () => import("./views/package/package_detail.vue"),
  },
  {
    path: "*",
    component: () => import("./views/pages/notFound"),
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.

  if (from.path.includes("manage")) {
    next();
  } else {
    if (to.path) {
      // Start the route progress bar.

      NProgress.start();
      NProgress.set(0.1);
    }
    next();
  }
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
