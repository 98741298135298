<template>
  <div id="app">
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 49%;left: 50%;">
          <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }"
            style="height: 3.5rem;width: 3.5rem;">
          </div>
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>
    </div>
    <Header></Header>
    <router-view></router-view>
    <!-- <PasswordModal></PasswordModal> -->
    <ModalError></ModalError>
    <b-modal id="modal-news" hide-footer>
      <div v-if="$root.newsItem" class="">
        <img :src="$root.newsItem.public_path" alt="" height="138px" width="100%" style="object-fit: cover;"
          class="rounded-1">
        <div class="text-left mt-3">
          <div class="text-20 font-weight-semibold mb-1">{{ $root.newsItem.title }}</div>
          <div class="text-secondary text-12 mb-2">{{ $root.newsItem.updated_at }}</div>
          <div class="mb-1 text-14" v-if="$root.newsItem.description"
            v-html="$root.newsItem.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>
        </div>
      </div>
    </b-modal>

    <b-modal :id="'modal-newlatest-' + item.id" hide-footer v-for="item in $root.lastNew" :key="item.id">
      <img :src="item.public_path" alt="" height="138px" width="auto" style="object-fit: cover;" class="rounded-1">
      <div class="text-left mt-3">
        <div class="text-20 font-weight-semibold mb-1">{{ item.title }}</div>
        <div class="text-secondary text-12 mb-2">{{ item.updated_at }}</div>
        <div class="mb-1 text-14" v-if="item.description" v-html="item.description.replace(/(\r\n|\r|\n)/g, '<br>')">
        </div>
      </div>
    </b-modal>
    <ForgetSecPassword></ForgetSecPassword>
    <ForgetPassword></ForgetPassword>
    <ChangePassword></ChangePassword>
    <ChangeSecPassword></ChangeSecPassword>

  </div>
</template>

<script>
import ModalError from './errorMsg.vue'
import Header from './header.vue';
import ForgetSecPassword from './views/profile/security/forget-sec-password.vue';
import ForgetPassword from './views/profile/security/forget-password.vue';
import ChangePassword from './views/profile/security/change-password.vue';
import ChangeSecPassword from './views/profile/security/change-sec-password.vue';
// import PasswordModal from './views/manage/security/passwordBUS.vue';
export default {
  components: {
    ModalError, Header, ForgetSecPassword, ForgetPassword,ChangeSecPassword,ChangePassword
  },
  watch: {
    $route() {
      var title = this.$route.path.split('/');
      this.title = title[title.length - 1];
      this.path = this.title;

    }
  },
  name: 'TEAM',
  data() {
    return {
      screenWidth: 0,
      title: '',
      path: '',
      username: '',
      address: '',
      logo: require('./assets/images/logo/team_logo.svg')
    }
  },
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          alert(`${this.$t('copySuccess')}: ` + value);
        })
        .catch((error) => {
          alert(error);
        });
    },
    logout() {
      localStorage.removeItem('currencyToken');
      localStorage.removeItem('info');
      this.$router.push('/web/sessions/login');
    },
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  created() {
    this.screenWidth = screen.width;

  },
  mounted() {
    var title = this.$route.path.split('/');
    this.title = title[title.length - 1];

    this.path = this.title;
    var info = JSON.parse(localStorage.getItem('info'));
    if (info)
      this.$root.username = info.username;
    console.log(this.$root.username);

    var token = (localStorage.getItem('currencyToken')) || '';
    if (token.length > 0)
      this.$root.getInfo();
  }
}
</script>

<style>
#app {
  font-family: 'PlusJakartaSans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
