

export default async (to, from, next) => {
  var token = localStorage.getItem("currencyToken") || '';
  var userInfo = localStorage.getItem("info") || '';


  try {
    userInfo = JSON.parse(userInfo);
  } catch (e) {
    userInfo = '';
  }

  console.log(to);

  if (token.length > 0) {
    if (userInfo.password2 == null) {
      next('/web/sessions/set-sec-password');
    }
    next();
  } else {
    localStorage.removeItem("currencyToken");
    next('/web/sessions/login');
  }

};
