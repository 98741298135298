<template>
    <div>
        <div class="header_wrap"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')"
            :class="{ 'bottom': $route.path.includes('homepage') }">
            <div class="content">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="logo py-2" @click="$router.push('/web')">
                        <img :src="require('./assets/images/logo/mobile_logo.svg')" alt="" width="auto" height="35px">

                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <div class="text-14 text-white ml-4 d-md-block d-none">
                            <b-button variant="primary" class="text-14" @click="$router.push('/web/trade')">{{
                $t('start_trading') }}</b-button>

                        </div> -->
                        <!-- <div class="ml-4" style="">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <span class="text-white text-14 text-uppercase">{{ $i18n.locale }}</span>
                                </template>
                                <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('in')">Indon</b-dropdown-item>
                            </b-dropdown>
                        </div> -->
                        <div id="tooltip-button-1" class="text-20  ml-4 text-white" @click="show = !show">
                            <i class="fa-regular fa-bell"></i>
                        </div>

                        <b-tooltip :show.sync="show" target="tooltip-button-1" placement="bottom">
                            <div class="title-news text-left">
                                {{ $t('news') }}
                            </div>
                            <div class="title-content d-flex" @click="openNews(item)" v-for="item in $root.newsList"
                                :key="item.id">
                                <img :src="item.public_path" alt="" height="48px" width="48px">
                                <div class="content ml-2 text-left">
                                    <div class="text-14 font-weight-semibold mb-1 text-ellipsis"
                                        style="max-width: 340px;">{{ item.title }}
                                    </div>
                                    <div class="text-secondary mb-1 max-line-2 text-14">{{ item.description }}</div>
                                    <div class="text-secondary text-12">{{ item.updated_at }}</div>

                                </div>

                            </div>
                        </b-tooltip>
                        <div class="text-20 text-white ml-4" v-b-toggle.sidebar-right>
                            <i class="fa-solid fa-bars"></i>
                        </div>

                    </div>
                </div>



                <div class="justify-content align-items-end d-flex" v-if="$route.path.includes('homepage')">
                    <h3 class="mt-3 text-black text-20 font-weight-bold">{{ $t('welcome') }} {{ $root.username }}
                    </h3>
                </div>

            </div>
        </div>
        <!-- <div class="d-flex justify-content align-items-end d-sm-none"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register') && !$route.path.includes('homepage') && !$route.path.includes('package') && !$route.path.includes('wallet')">
            <h3 class="my-3 mx-4 text-20 font-weight-semibold">{{ $t($parent.title) }}</h3>
        </div> -->
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="profile-outer-box mb-4">
                <span>{{ $t('plan_info') }}</span>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile" class="">
                    <span class="nav-text">
                        {{ $t("profile_info") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/settings" class="">
                    <span class="nav-text">
                        {{ $t("sec_settings") }}
                    </span>
                </router-link>
            </div>

            <!-- <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=3" class="">
                    <span class="nav-text">
                        {{ $t("kyc") }}
                    </span>
                </router-link>
            </div> -->

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/referral" class="">
                    <span class="nav-text">
                        {{ $t("invite_link") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/setWithdraw" class="">
                    <span class="nav-text">
                        {{ $t("withdraw_way") }}
                    </span>
                </router-link>
            </div>
            <div class="flex-grow-1"></div>

            <div class="text-left mb-4">
                <span class="nav-text logout" @click="$parent.logout">
                    {{ $t("logout") }}
                </span>
            </div>
            <!-- <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=3" class="">
                    <span class="nav-text">
                        {{ $t("kyc") }}
                    </span>
                </router-link>
            </div> -->
        </b-sidebar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false,
        }
    },
    methods: {
        openNews(item) {
            this.show = !this.show;
            this.$root.newsItem = item;
            this.$bvModal.show('modal-news');

        }
    }

}
</script>