<template>
  <b-modal id="forget_secpassword" centered :title="$t('forget_secpassword')" hide-footer>
    <div class="text-left">
      <b-form @submit.prevent="sendOTP">
        <b-form-group class="">
          <h4 class="font-weight-semibold text-14">{{ $t('email') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="email" type="text" :placeholder="$t('email')"
              readonly></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group class="">
          <h4 class="font-weight-semibold text-14">{{ $t('vcode') }}</h4>
          <b-input-group>
            <b-form-input class="no-border" label="text" type="number" v-model="otp" :placeholder="$t('vcode')"
              required>
            </b-form-input>
            <template #append>
              <b-button variant="primary" @click="getOTP" :disabled="startCount || sending">
                {{ startCount ? timecount + 's' : !sending ? $t('getCode') : $t("loading...") }}
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>

        <b-form-group class="mt-4">
          <h4 class="font-weight-semibold text-14">{{ $t('new_password') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="password" type="password"
              :placeholder="$t('new_password')" required></b-form-input>
          </b-input-group>
        </b-form-group>


        <b-form-group class="mt-4">
          <h4 class="font-weight-semibold text-14">{{ $t('confirm_new_password') }}</h4>
          <b-input-group>
            <b-form-input class="form-control form-custom" v-model="password_confirmation" type="password"
              :placeholder="$t('confirm_new_password')" required></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-button class="d-block mt-4 ml-auto px-4" variant="primary" type="submit" :disabled="$root.isLoading">{{
    isLoading ? $t("loading...") : $t("submit") }}</b-button>


      </b-form>

    </div>
  </b-modal>
</template>

<script>
import {
  requestOTP,
  checkOTP,
  forgetSecPassword,
} from "../../../system/request/api/api";
import { handleError } from "../../../system/request/handleRes";
export default {
  computed: {
  },
  components: {
  },
  data() {
    return {
      email: "",
      country: "",
      otp: "",
      password: "",
      password_confirmation: "",
      timecount: 60,
      startCount: false,
      myVar: null,
      sending: false,
      isLoading: false,
    };
  },
  props: ["success"],
  methods: {
    getInfo() {

      var info = JSON.parse(localStorage.getItem('info'));
      if (info)
        this.email = info.email;
    },
    sendOTP() {
      let formData = new FormData();
      var self = this;
      formData.append("otp", this.otp);
      var result = checkOTP(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code.toString() == '0') {
            self.$root.makeToast("success", self.$t(value.data.message));
            self.submitForm();
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    submitForm() {
      let formData = new FormData();
      var self = this;
      formData.append("password", this.password);
      formData.append("password_confirmation", this.password_confirmation);
      var result = forgetSecPassword(formData);
      self.isLoading = true;

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.error_code == 0) {
            self.$root.makeToast("success", self.$t(value.data.message));
            self.otp = '';
            self.password = '';
            self.password_confirmation = '';
            self.$bvModal.hide('forget_secpassword');
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.isLoading = false;
        });
    },
    getOTP() {
      if (this.username == "") {
        this.$root.makeToast("danger", this.$t("emailEmpty"));
      } else {
        this.sending = true;
        this.isLoading = true;
        let formData = new FormData();
        var self = this;
        formData.append("otp_type", "email");
        var result = requestOTP(formData);

        result
          .then(function (value) {
            console.log(value.data);
            if (value.data.code.toString() == '0') {
              self.$root.makeToast("success", self.$t("otp_sent"));
              self.startCount = true;
              self.myVar = setInterval(() => {
                self.timecount -= 1;
                if (self.timecount == 0) {
                  self.timecount = 60;
                  clearInterval(self.myVar);
                  self.startCount = false;
                }
              }, 1000);
            } else {
              self.$root.makeToast("danger", self.$t(value.data.message));
            }
            self.sending = false;
            self.isLoading = false;
          })
          .catch(function (error) {
            self.$root.makeToast("warning", self.$t(handleError(error)));
            self.sending = false;
            self.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getInfo();
    this.username = localStorage.getItem("username") ?? '';
  },
};
</script>
